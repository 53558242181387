define(['jquery', 'siteObj', 'common'], function($, siteObj) {
  var component = {};
  // var ReturnsForm;

  // if (siteObj.siteCode === 'myprotein') {
  //   ReturnsForm = siteObj.siteNonSecureURL + 'common/docs/returns-form-myprotein.pdf';
  // } else {
  //   ReturnsForm = siteObj.siteNonSecureURL + 'common/docs/returns_form_appleton.pdf';
  // }

  /* contact us page */
  $('.catlink').on('click', function() {
    //var catname = $(this).attr('class').split(' ')[1];

    var linkTextName = $(this).text(); // this area sets the tracking codes for the Cat links
    push_data_to_gaEventTracking('contact', linkTextName, 'Catlink');

    $('[class^="help-faq"]').addClass('hidden');

  });

  $('.contact-us.bsam-contact .ss-links a').on('click', function() {
    var linkTextName = $(this).text(); // this area sets the tracking codes for the Selfserv links
    push_data_to_gaEventTracking('contact', 'Selfservlink', linkTextName);

  });

  $('.topiclink').on('click', function() {
    var linkTextName = $(this).text(); // this sets the tracking codes for the Topic links
    push_data_to_gaEventTracking('contact', 'Topiclink', linkTextName);

    var topicname = $(this).attr('class').split(' ')[1];
    $('[class^="help-faq"]').addClass('hidden');

    if (topicname === 'where-order') {
      $('#results-here').show();
      $('[class^="help-faq"]').addClass('hidden');
      $('.help-faq-1').toggleClass('hidden');

    } else if (topicname === 'receive-order') {
      $('#results-here').show();
      $('[class^="help-faq"]').addClass('hidden');
      $('.help-faq-2a').toggleClass('hidden');

    } else if (topicname === 'track-delivery') {
      $('#results-here').show();
      $('[class^="help-faq"]').addClass('hidden');
      $('.help-faq-2b').toggleClass('hidden');

    } else if (topicname === 'cancel-order') {
      $('#results-here').show();
      $('[class^="help-faq"]').addClass('hidden');
      $('.help-faq-3').toggleClass('hidden');

    } else if (topicname === 'nutrition-info') {
      $('#results-here').show();
      $('[class^="help-faq"]').addClass('hidden');
      $('.help-faq-4').toggleClass('hidden');

    } else if (topicname === 'amend-order') {
      $('#results-here').show();
      $('[class^="help-faq"]').addClass('hidden');
      $('.help-faq-5').toggleClass('hidden');

    } else if (topicname === 'return-item') {
      $('#results-here').show();
      $('[class^="help-faq"]').addClass('hidden');
      $('.help-faq-6').toggleClass('hidden');

    } else if (topicname === 'place-order') {
      $('#results-here').show();
      $('[class^="help-faq"]').addClass('hidden');
      $('.help-faq-7').toggleClass('hidden');

    } else if (topicname === 'account-info') {
      $('#results-here').show();
      $('[class^="help-faq"]').addClass('hidden');
      $('.help-faq-8').toggleClass('hidden');

    } else if (topicname === 'promo-codes') {
      $('#results-here').show();
      $('[class^="help-faq"]').addClass('hidden');
      $('.help-faq-9').toggleClass('hidden');

    } else if (topicname === 'reward-points') {
      $('#results-here').show();
      $('[class^="help-faq"]').addClass('hidden');
      $('.help-faq-10').toggleClass('hidden');

    } else if (topicname === 'problem-order') {
      $('#results-here').show();
      $('[class^="help-faq"]').addClass('hidden');
      $('.help-faq-11').toggleClass('hidden');

    } else {
      $('#results-here').hide();
    }

    $('#results-here a').on('click', function() {
      var linkTextName = $(this).text(); // this area sets the tracking codes for the Faq links
      push_data_to_gaEventTracking('contact', 'Faqlink', linkTextName);
    });
  });


  var push_data_to_gaEventTracking = function(linkpageName, linkSectionName, linkTextName) {
    linkTextName = linkTextName.replace(/ /g, '-').toLowerCase();
    var galinkTextName = linkSectionName + '_' + linkTextName;
    var galinkPageName = 'progand_' + linkpageName;
    siteObj.fn.gaEventTrack(galinkPageName, 'click', galinkTextName);
  };

  // var push_data_to_gaEventTracking_select = function(selectPageName, selectSectionName, selectTextName) {
  //   selectTextName = selectTextName.replace(/ /g, '-').toLowerCase();
  //   var gaselectTextName = selectSectionName + '_' + selectTextName;
  //   var gaselectPageName = 'progand_' + selectPageName;
  //   siteObj.fn.gaEventTrack(gaselectPageName, 'select', gaselectTextName);
  // };
  return component;
});
